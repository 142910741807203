@import "boostrap-custom";
@import "bootstrap";

@import "ember-basic-dropdown";
@import "ember-power-calendar";
@import "light-table";

@import "datepicker";

/* Change cursor for rows of light-table */

.lt-row {
  cursor: pointer;
}

tr.completed {
  background: transparentize(green, 0.9);
}

h2 {
  margin: 0;
  margin-bottom: 10px;
  font-size: 20px;
  border-bottom: 1px solid transparentize(black, 0.5);
  padding-bottom: 5px;
}

/* Make a.active links styles work the same as li.active */
.navbar-default .navbar-nav {
  li {
    a.active {
      &, &:hover {
        color: white;
        background-color: #235fb3;
      }
    }
  }
}

.form-text {
  padding-top: $padding-base-vertical+1px;
  border-color: $input-bg!important;
  @include box-shadow(inset 0 1px 1px $input-bg);
}

.glyphicon.spinning {
  animation: spin 1s infinite linear;
  -webkit-animation: spin2 1s infinite linear;
}

@keyframes spin {
  from { transform: scale(1) rotate(0deg);}
  to { transform: scale(1) rotate(360deg);}
}

@-webkit-keyframes spin2 {
  from { -webkit-transform: rotate(0deg);}
  to { -webkit-transform: rotate(360deg);}
}

.dropdown-menu {
  position: relative;
  top: auto;
  left: auto;
  display: block;
  padding: 5px;
}

.task-options {
  padding: 5px;
}

.task-options-dropdown {
  input[type=checkbox] {
    transform: scale(1.4);
    margin: 10px;
  }

  input {
    margin-bottom: 5px;
  }
}

.dropdown {
  position: relative;
  display: inline-block;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 14px;
}

.dropdown > a, .dropdown > button {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 14px;
  background-color: white;
  border: 1px solid #ccc;
  padding: 6px 20px 6px 10px;
  border-radius: 4px;
  display: inline-block;
  color: black;
  text-decoration: none;
}

.dropdown > a:before, .dropdown > button:before {
  position: absolute;
  right: 7px;
  top: 14px;
  content: ' ';
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid black;
}

.dropdown input[type=checkbox] {
  position: absolute;
  display: block;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  margin: 0px;
  opacity: 0;
}

.dropdown input[type=checkbox]:checked {
  position: fixed;
  z-index:+0;
  top: 0px; left: 0px; 
  right: 0px; bottom: 0px;
}

.dropdown ul {
  position: absolute;
  top: 30px;
  border: 1px solid #ccc;
  border-radius: 3px;
  left: 0px;
  list-style: none;
  padding: 4px 0px;
  display: none;
  background-color: white;
  box-shadow: 0 3px 6px rgba(0,0,0,.175);
}

.dropdown input[type=checkbox]:checked + ul {
  display: block;
}

.dropdown ul li {
  display: block;
  padding: 2px 5px;
  white-space: nowrap;
  min-width: 100px;
}

.dropdown ul li a {
  text-decoration: none;
  display: block;
  color: black
}

.dropdown .divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: #e5e5e5;
  font-size: 1px;
  padding: 0;
}


@import "ember-bootstrap/bootstrap";
